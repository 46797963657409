a,
.btn-link {
  color: rgb(0, 136, 204);
  text-decoration: none;
}

a:hover,
.btn-link:hover {
  color: #005580;
  cursor: pointer;
  text-decoration: underline;
}

.btn-link {
  background-color: transparent;
}

// This is to override a 24px margin-bottom in maxmind/styles.scss. We should
// remove that after we have migrated everything to Bootstrap 5 unless we need
// it for a particular reason.
#content {
  form {
    margin-bottom: 0;
  }

  .card {
    margin-bottom: 20px;
    width: max-content;
  }

  // This is an alternative to using the card class. It doesn't set a
  // specified width like the above .card class does.
  .bg-light.border {
    margin-bottom: 20px;
  }

  .border .card-body {
    padding: 20px;
  }
}

input:focus:invalid,
textarea:focus:invalid,
select:focus:invalid {
  color: #b94a48;
  border-color: #ee5f5b;
  box-shadow: 0 0 6px #f8b9b7;
}

input:placeholder-shown:focus,
input:valid:focus,
select:placeholder-shown:focus,
select:valid:focus,
textarea:placeholder-shown:focus,
textarea:valid:focus {
  border-color: #52a8eccc;
  box-shadow:
    inset 0 1px 1px #00000013,
    0 0 8px #52a8ec99;
}

pre {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: block;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 10px;
  padding: 9.5px;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}

.progress {
  height: 20px;
}

.progress-bar {
  background-color: #149bdf;
}

.form-control,
.form-select {
  border-radius: 0;
}
