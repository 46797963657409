$geoip-input-height: 65px;
$geoip-input-height-mobile: 50px;

.section__geoip-demo {
  background: #344653;
  padding-bottom: 30px;
  padding-top: 30px;

  @media (min-width: $bp-sm-min) {
    display: flex;
    padding-bottom: 60px;
    padding-top: 60px;
  }
}

.geoip-demo {
  @media (min-width: $bp-sm-min) {
    display: flex;
  }
}

.geoip-demo__headings {
  margin-bottom: 1rem;

  @media (min-width: $bp-sm-min) {
    margin-bottom: 0;
    margin-right: 50px;
    min-width: 250px;
  }

  @media (min-width: $bp-md-min) {
    min-width: 300px;
  }
}

.geoip-demo__heading,
.geoip-demo__subheading {
  color: #fff;
  margin: 0;
}

.geoip-demo__heading {
  font-size: 1.125rem;
  font-weight: $weightLight;
  line-height: 1em;

  @media (min-width: $bp-md-min) {
    font-size: 1.3125rem;
  }
}

.geoip-demo__subheading {
  font-size: 1.75rem;
  font-weight: $weightNormal;
  line-height: 1.5em;

  @media (min-width: $bp-md-min) {
    font-size: 1.875rem;
  }
}

.geoip-demo__form {
  width: 100%;

  @media (min-width: $bp-sm-min) {
    display: flex;
  }
}

input.geoip-demo__input {
  height: $geoip-input-height-mobile;
  margin-bottom: 1rem;

  @media (min-width: $bp-sm-min) {
    flex: 1 0 auto;
    height: $geoip-input-height;
    margin: 0;
    width: auto;
  }

  &.is-invalid {
    border: 1px solid #f94d4d;
    box-shadow: inset 0 0 3px 0 rgba(249, 77, 77, 0.8);
  }
}

.geoip-demo__submit {
  display: block;
  font-size: 1.25rem;
  font-weight: $weightNormal;
  height: $geoip-input-height-mobile;
  padding: 0 1rem;
  text-transform: uppercase;

  @media (min-width: $bp-sm-min) {
    align-items: center;
    display: flex;
    height: $geoip-input-height;
    margin-left: 20px;
    padding: 0 2rem;
  }
}

#geoip-demo.city .sticky-table__scroller,
#geoip-demo.insights .sticky-table__scroller {
  margin-bottom: 20px;

  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ddd;
  }

  &::-webkit-scrollbar-thumb {
    background: #aaa;
  }
}

#geoip-demo.city .geoip-demo__table,
#geoip-demo.insights .geoip-demo__table {
  margin-bottom: 0;
}

@media (min-width: 1054px) {
  #content-container.span12 #geoip-demo.city .sticky-table__scroller,
  #content-container.span12 #geoip-demo.insights .sticky-table__scroller {
    left: 50%;
    margin-bottom: 20px;
    max-width: 90vw;
    position: relative;
    transform: translateX(-50%);
  }
}
