#content {
  margin-bottom: 40px;

  .content__section {
    margin-bottom: 36px;
  }

  p {
    margin-bottom: 20px;
  }

  .content__headline {
    margin-bottom: 30px;
  }

  .content__sub-header {
    margin: 0 0 20px;
  }

  .content__img-title {
    margin-bottom: 10px;
  }

  .content__img {
    border: 2px solid #567da7;
    margin-bottom: 1px;
  }

  .content__img-text {
    color: #365956;
    font-size: 0.9375rem;
    font-weight: $weightLight;
    margin-top: 10px;
  }

  .content__btn {
    margin-top: 6px;
  }

  .content__btn:last-child {
    // bottom margin to account for line-height spacing of proceeding content
    margin: 6px 0;
  }

  .content__leading-phrase {
    color: $maxmindDarkBlueGray;
    font-size: 0.9375rem;
    font-weight: $weightBold;
  }

  .content__asterisk {
    color: #364956;
    font-size: 0.9375rem;
    font-weight: $weightBold;
    position: relative;
    right: -2px;
    top: -2px;
  }

  .content__asterisk--footnote {
    position: static;
  }

  .content__footnote {
    color: #364956;
    font-size: 0.8125rem;
    font-weight: $weightNormal;
  }

  .content__footnote-link {
    font-weight: $weightMedium;
  }

  .anchor-target:before {
    content: '';
    display: block;
    height: 68px; // Height of fixed header
    margin-top: -68px;
    visibility: hidden;
  }

  .scroll-margin-top {
    scroll-margin-top: 80px;
  }
}

.table--no-overflow {
  table-layout: fixed;

  td {
    word-wrap: break-word;
  }
}
