body.receipt {
  padding-top: 30px;
}
.receipt-info dt {
  width: 100px;
}
.receipt-info dd {
  margin-left: 115px;
}
.receipt-info address {
  margin-bottom: 0px;
}
.receipt-info {
  margin: 0px;
}
.receipt-footer dt {
  display: inline;
  margin: 0px 0px 0px 20px;
  padding: 0px;
}
.receipt-footer dt:first-child {
  margin-left: 0px;
}
.receipt-footer dd {
  display: inline;
  margin: 0px;
  padding: 0px;
}
.receipt-items td,
.receipt-items th,
.receipt-items {
  border: 0 !important;
}
.receipt-logo {
  margin-bottom: 15px;
  width: 200px;
}
