#ws-logs-start-date-picker .add-on i,
#ws-logs-end-date-picker .add-on i {
  margin-top: 0;
}

.ws-logs-control label {
  display: inline-block;
  min-width: 50px;
}

/* By default lists get indented and have a bullet added. I think this looks
 * strange when the checkbox comes first, so override it. */
#ws-logs-keys {
  margin: 0 !important;
}
#ws-logs-keys li:before {
  content: none !important;
  margin: 0;
}
