$side-padding: 20px;
$vert-padding: 8px;
$vert-padding--tight: 5px;

.mm-table {
  margin-bottom: 10px;
}

.mm-table__body > .mm-table__row:first-child > .mm-table__td {
  padding-top: calc($vert-padding * 2);
}

.mm-table__body > .mm-table__row:last-child > .mm-table__td {
  padding-bottom: calc($vert-padding * 2);
}

.mm-table__td,
.mm-table__th {
  font-size: 0.875rem;
  line-height: $contentLineHeight;
}

.mm-table__th {
  color: #364956;
  font-weight: 700;
  padding: 0 0 $vert-padding $side-padding;
  text-align: left;
}
.mm-table__th--center {
  padding-left: 0;
  text-align: center;
}

.mm-table__td {
  color: #3e83b7;
  font-weight: 400;
  padding: $vert-padding $side-padding;
  vertical-align: top;

  &:first-child {
    font-weight: 500;
    min-width: 200px;
  }
}

.mm-table__td--no-pad {
  padding: 0;
}

.mm-table__row--tight .mm-table__td {
  padding-bottom: $vert-padding--tight;
  padding-top: $vert-padding--tight;
}

.mm-table__pre-summary > .mm-table__td,
.mm-table__pre-nested > .mm-table__td {
  padding-bottom: calc($vert-padding * 2);
}

.mm-table__summary {
  background: #fff;
}

.mm-table__summary > .mm-table__td {
  border-top: 2px solid #ccc;
  color: #364956;
  font-weight: 700;
  padding: calc($vert-padding * 2) $side-padding;
}

.mm-table--primary .mm-table__body {
  background: #eaf8f9;
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
}

.mm-table--nested .mm-table__body {
  border: 2px solid #eee;
}

/* Service Comparison */

.mm-table__td--product {
  position: relative;
  text-align: center;

  &:before {
    content: '';
    background: #fff;
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 2px;
  }
}

.mm-table__td--label {
  width: 240px;
}

.mm-table__td--outputs {
  background: #f9f9f9;
}

.mm-table__td--score {
  background: #f9f9f9;
  width: 95px;
}

.mm-table__td--insights {
  background: #f4fafa;
  width: 95px;
}

.mm-table__td--factors {
  background: #ddf4f5;
  width: 95px;
}

.mm-table__check {
  color: #4a90e2;
  font-size: 1.25rem;
}

.mm-table__collapse-trigger {
  color: #0a95da;
  cursor: pointer;
  float: right;
  font-weight: $weightNormal;

  .fa-caret-left {
    display: none;
  }

  &.collapsed {
    .fa-caret-left {
      display: inline-block;
    }
    .fa-caret-down {
      display: none;
    }
  }
}
.mm-table__trigger-icon {
  display: inline-block;
  width: 10px;
}
