/* Copied from bootstrap/less/mixins.scss. Less will simply _add_ these styles
to the ones already defined by bootstrap, so we need to remove the bootstrap
gradient explicitly. This is completely gross but the alternative is to edit
the bootstrap source which we really want to avoid. */

@mixin buttonBackground(
  $startColor,
  $endColor,
  $textColor: #fff,
  $textShadow: 0 -1px 0 rgba(0, 0, 0, 0.25)
) {
  background-image: none;
  background-repeat: none;
  filter: none;

  background-color: $startColor;

  text-decoration: none;

  // in these cases the gradient won't cover the background, so we override
  &:hover,
  &:focus,
  &:active,
  &.active,
  &.disabled,
  &[disabled] {
    color: $textColor;
    background-color: darken($startColor, 10%);
  }

  // IE 7 + 8 can't handle box-shadow to show active, so we darken a bit ourselves
  &:active,
  &.active {
    background-color: darken($endColor, 10%) e('\9');
  }
}

@mixin flow-indicator() {
  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  // line
  &:before {
    background: $mmLightGray;
  }

  // circle
  &:after {
    border: 1px solid $mmLightGray;
    border-radius: 50%;
    box-sizing: border-box;
    height: 12px;
    width: 12px;
  }
}

@mixin flow-indicator($direction) {
  @if ($direction == vertical) {
    @include flow-indicator();

    &:before,
    &:after {
      left: 50%;
    }

    &:before {
      height: 60px;
      top: -40px;
      width: 1px;
      z-index: 120;

      @media (min-width: $bp-md-min) {
        height: 100px;
      }
    }

    &:after {
      margin-left: -5px;
      top: 25px;

      @media (min-width: $bp-md-min) {
        top: 65px;
      }
    }
  }

  @if ($direction == horizontal) {
    @include flow-indicator();

    &:before {
      height: 1px;
      margin-right: 55px;
      margin-top: 6px;
      right: 100%;
      top: 0.5em;
      width: 2000px;
    }

    &:after {
      left: -50px;
      top: 0.5em;
    }
  }
}

.no-wrap {
  white-space: nowrap;
}

.hyphenated-word-wrap {
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.small-text {
  font-size: 0.8125rem;
  line-height: 18px;
}

.no-top-space {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.roundCorners {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.well--vertical-margin-10 {
  margin: 10px 0;
}

.well--dark-bg {
  background: #c8d2de;
}

.well--no-bg {
  background: #fff;
}

.well--plain {
  background: #fff;
  border: 1px solid #567da7;
}

.well--light-bg {
  background: #f8f9f7;
  border: 1px solid #ccc;
}

.well-header {
  margin: 0 0 10px 0;
}

.well-header__title,
#content .well-header__title {
  margin: 10px 0 5px;
}

.well-header__title--tight,
#content .well-header__title--tight {
  margin: 0 0 5px;
  padding: 0;
}

.well__btn {
  padding: 4px 45px;
}

.well__btn--top-space {
  margin-top: 10px;
}

.well__border {
  box-sizing: border-box;
  border: 1px solid #cccccc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well__basic {
  background: none;
  font-size: 0.875rem;
}

.span-175px {
  width: 175px;
}

.span-465px {
  width: 465px;
}

.text-capitalize {
  text-transform: capitalize;
}

.no-margin {
  margin: 0 !important;
}

.row-table-align {
  margin-left: -10px;
  padding-right: 8px;
}

.block {
  display: block;
}

.border-box {
  box-sizing: border-box;
}

.offset1--padding {
  padding-left: 65px;
}

.border-left {
  border-left: 1px solid $maxmindDarkBlueGray;
}

.span1-5 {
  width: 105px;
}

.text-error > select {
  @extend .text-error;
}

.help-block--tight {
  line-height: 1.2em;
  margin-top: 15px;
}

.vertical-spacing--15-0 {
  margin-top: 15px;
}

.opacity--04 {
  opacity: 0.4;
}

.is-clickable {
  cursor: pointer;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.hidden {
  display: none !important;
}

.clear {
  clear: both !important;
}

.table__cell--inverse {
  background: #364956 !important;
  color: #fff;
}

.table-feature-list td:first-child {
  width: 30%;
}

.table--heading-border td {
  border-top: none;
  padding: 4px 0;
}

.table--heading-border th,
.table--heading-border td {
  padding-left: 0px;
}

.table--heading-border thead {
  border-bottom: 1px solid #ddd;
}

.control-mixed {
  margin-bottom: 15px;
}

.control-mixed__label {
  float: left;
  margin-top: 5px;
  width: 135px;
}

.vertically-align {
  align-items: center;
  display: flex;
}

$PATTERN_SCALE: 1;

@mixin pattern($color: rgba(255, 255, 255, 0.3)) {
  position: relative;

  &::before {
    background-attachment: fixed;
    background-color: #00a7e5;
    background-image: url('../js/marketing/static/bg-pattern.svg');
    background-position: 0 0;
    background-size: (80px * $PATTERN_SCALE) (140px * $PATTERN_SCALE);
    content: ' ';
    height: 100%;
    left: 0;
    position: absolute;
    transform: translateZ(0);
    top: 0;
    width: 100%;
    will-change: transform;
  }

  &::after {
    background: rgb(0, 119, 194);
    background: linear-gradient(90deg, rgba(0, 119, 194, 0.5) 0%, $color);
    content: ' ';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.pattern-default {
  @include pattern;
}
