.account__save-btn {
  display: block;
  margin-left: 440px;
}

#content .account__form {
  margin-bottom: 0;
}

#content .account__heading {
  border-bottom: 1px solid #beced7;
  color: #172831;
  margin-bottom: 25px;
  padding-bottom: 5px;
}

.account-service__service-name {
  width: 28%;
}

.account-service__queries {
  width: 34%;
}

.account-service__billing {
  width: 38%;
}

.account-db__heading {
  float: left;
  padding-right: 17px;
  width: 425px;
}

.account-db__download-links {
  float: left;
  margin: 5px 0 10px;
}

.account-db__db-name {
  width: 28%;
}

.account-db__access-date {
  width: 17%;
}

.account-db__update {
  width: 38%;
}

.account-db__sub-pricing {
  color: $dbPricingColor;
}

.account-db__sub-active {
  color: #438344;
}

.account__billing {
  float: left;
  padding-right: 15px;
  width: 90px;
}

.account__cancel-sub {
  float: left;
}

.account-balance__billing-method {
  width: 300px;
}

.account-balance__billing-method p {
  &:last-child {
    margin-bottom: 0 !important;
  }
}

.account-balance__billing-method-amount {
  display: block;
}

.account-balance__invoice-msg {
  margin-bottom: 20px;
}

.account-queries__toggle {
  cursor: pointer;
  line-height: 30px;
}

.account-queries__name,
.account-queries__count {
  font-size: 0.875rem;
}

.account-queries__name {
  width: 60%;
}

.table .account-queries__count {
  text-align: right;
}

.account-billing {
  border-bottom: 1px solid #ccc;
  margin: 15px 0 25px;
}

.account-billing__payg,
.account-billing__recharge {
  margin-bottom: 25px;
}

.account-billing__payg {
  border-bottom: 1px solid #000;
  padding-bottom: 25px;
}

.account-billing__options {
  clear: left;
}

.account-billing__label {
  display: block;
  font-size: 18px;
  margin-bottom: 20px;
}

.account-billing--disabled,
.account-billing--disabled * {
  cursor: not-allowed !important;
  opacity: 0.5;
  text-decoration: none !important;
}

.account-billing__actions {
  font-size: 14px;
}

.account-billing__recharge-heading {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.account-billing__recharge-amount {
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

#content .account-billing__form {
  margin-bottom: 10px;
}

.account-billing__input {
  width: 75px;
}

.account-billing__btn {
  cursor: pointer;
  margin-left: 5px;
  min-width: 100px;
}

.account-billing__btn[disabled] {
  cursor: not-allowed;
}

.account-billing__btn + .account-billing__btn {
  margin-left: 5px;
}

.account-billing__admin-eula-warning {
  margin-bottom: 1em;
}

.account-billing__admin-eula-checkbox {
  margin-top: 1em;
}

.account-license-keys__geoip-update {
  background: $maxmindBlueGray;
  border: 1px solid #d2dae3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  padding: 19px;
}

// 2FA

.show-active {
  display: none;

  .is-active & {
    display: block;
  }
}

.show-inactive {
  display: none;

  .is-inactive & {
    display: block;
  }
}

.show-incomplete {
  display: none;

  .is-incomplete & {
    display: block;
  }
}

.is-incomplete .hide-incomplete {
  display: none;
}

.show-on-client-cannot-2fa {
  display: none;

  .client-cannot-2fa & {
    display: block;
  }
}

.hide {
  display: none;
}

.hide-on-client-cannot-2fa {
  display: block;

  .client-cannot-2fa & {
    display: none;
  }
}

.two-factor__recovery-method {
  background: #f7f7f7;
  border: 1px solid #d2edfc;
  margin-bottom: 15px;
  padding: 10px 15px 20px;
}

.two-factor__recovery-method--active {
  background: #e3eff7;
  border-color: #a3daf8;
}

.two-factor__remove-key {
  background: $maxmindMediumBlue;
  border: 0;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  display: inline-block;
  float: right;
}

.two-factor__remove-key:hover {
  background: $maxmindBrightBlue;
}

.recovery-method__title {
  font-size: 1.125rem;
}

.recovery-method__action {
  background: none;
  border: none;
  color: $maxmindBrightBlue;
  font-size: 0.9375rem;
  font-weight: $weightMedium;
  padding: 0;

  &:hover,
  &:focus {
    color: $maxmindBrightBlue;
    text-decoration: underline;
  }
}

.recovery-method__add-link {
  padding-left: 1em;
  position: relative;

  &:before {
    content: '+';
    left: 0;
    position: absolute;
  }
}

#content .recovery-method__form {
  margin-bottom: 0;
}

.recovery-method__remove {
  margin-top: 20px;
  text-align: right;
}

.recovery-method__remove-btn {
  &,
  &:hover,
  &:focus {
    border-width: 2px;
  }

  &:hover,
  &:focus {
    background: #f94d4d;
    border: 2px solid #f94d4d;
    color: #fdfbdc;
  }
}

.recovery-method__modal-cancel-btn {
  float: left;
}

.recovery-method__action-footer {
  border-top: 1px solid #000;
  margin-top: 30px;
  padding-top: 20px;
  text-align: right;
}

/* 2FA - One-time access codes */

.ota-codes {
  align-items: center;
  background: #f3f3f3;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 40px;
  padding: 14px 12%;
}

.ota-codes__cell {
  flex-basis: 46%;
  margin: 6px 2%;
  text-align: center;
}

.ota-codes__code {
  background: #fff;
  color: #000;
  display: block;
  font-weight: $weightBold;
  padding: 8px 0;
  text-align: center;
  width: 100%;
}

.ota-codes__textarea {
  height: 0;
  left: 0;
  opacity: 0;
  padding: 0;
  position: fixed;
  top: 0;
  width: 0;
  z-index: -1;
}

.ota-codes__copied-msg {
  margin-top: 20px;
}

/* 2FA - Deactivate */

.deactivate-2fa {
  margin-top: 40px;
}

.deactivate-2fa__btn {
  max-width: 250px;

  &,
  &:hover,
  &:focus,
  &:active {
    border: 2px solid #d0021b;
  }
}

.deactivate-2fa__btn--right {
  float: right;
  margin-left: 30px;
}

/* 2FA - Last OTA code used = no recovery */
.no-recovery {
  margin-bottom: 40px;
}

.account__license-key-account-id {
  text-align: center !important;
}

.account__license-key-dl {
  display: flex;
  margin-bottom: 0 !important;
}

.account__license-key-list .account__license-key-dl {
  justify-content: center;
}

.account__license-key-dl .boo-account_id,
.account__license-key-dl .js-license_key,
.account__license-key-dl .account__license-key-last-used {
  margin-left: 5px;
}

.account__license-key-description {
  max-width: 200px;
}

.account__license-key-prefix::after {
  clear: none !important;
  content: '...' !important;
  display: inline-block !important;
}

.account__plain-text-license-key {
  font-family: monospace;
  font-size: 15px;
}

.account__license-key-clipboard-success,
.account__license-key-clipboard {
  margin-left: 5px;
}

.account__license-key-clipboard-success {
  color: #4bb543;
}

.account__license-key-clipboard-fail {
  color: #db0f13;
}

td.js-license_key {
  white-space: nowrap;
}

.js-license_key .bi-exclamation-triangle {
  color: #f28029;
  font-size: 1rem;
  vertical-align: bottom;
}

.js-license_key .bi-exclamation-triangle:hover {
  cursor: pointer;
}

#content .js-license_key h3.popover-title {
  font-size: 0.9rem;
}

#content .js-license_key .popover-content {
  font-size: 1rem;
}

.download__column--fixed {
  white-space: nowrap;
  width: 150px;
}

.permalink-modal__header {
  display: inline-block;
}

.permalink-modal__header--weak {
  font-weight: normal;
}

.permalink-modal__close {
  margin-top: 12px;
}

.permalink-modal__code-comment {
  color: #999;
}

#content .resources__list {
  margin-bottom: 0;
}

.account__request-access-modal {
  color: #364956;
}

.account__request-access-modal
  .minfraud-estimated-monthly-queries__description {
  display: none;
}

#minfraud-alert-log dl {
  margin-bottom: 5px;
}

#minfraud-alert-log dd + dt {
  margin-top: 8px;
}

#minfraud-alert-log pre {
  font-size: 12px;
  margin-bottom: 0px;
}

#minfraud-alert-log .accordion {
  margin-bottom: 0px;
}

#minfraud-alert-log .nowrap {
  white-space: nowrap;
}
