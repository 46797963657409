.left-nav {
  color: #3e83b7;
  font-size: 0.875rem;
  font-weight: 300;
  list-style: none;
  margin: -5px 0 20px;
  padding: 0;
}

.left-nav__header {
  color: #3e83b7;
  font-size: 0.875rem;
  font-weight: 500;
  margin: 10px 0;
  padding: 0;
  line-height: 18px;
}

.left-nav__item {
  border: 1px solid #567da7;
  margin-bottom: -1px;
}

.left-nav__link {
  color: #3e83b7;
  display: block;
  font-family: $displayFontFamily;
  line-height: 18px;
  padding: 9px 12px;
  word-wrap: break-word;
}

.left-nav__link:hover {
  background: #88d4fa;
  color: #364956;
  text-decoration: none;
}

.left-nav__link:focus,
.left-nav__link:active {
  background: #6acbfb;
  color: #364956;
  text-decoration: none;
}

.left-nav__link--active {
  background: #c8eafb;
}

.left-nav__link--active .left-nav__link {
  color: #364956;
}

.sidebar__cta {
  margin: 0 0 20px 0;
}

.sidebar__links {
  margin-left: 12px;
}

.sidebar__link {
  color: #0a95da;
  font-size: 1rem;
  font-weight: 600;
}
