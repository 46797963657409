#cart-my-order-ko {
  max-width: 1000px;
}

.cart {
  margin-top: -10px;
}

.cart__heading--mini,
#content .cart__heading--mini {
  font-size: 1.125rem;
  margin: 0 0 0 6px;
}

.cart__header--mini {
  line-height: 35px;
  padding-right: 5px;
}

.cart td,
.cart label {
  line-height: 18px;
}

.cart__total {
  width: 460px;
}

#cart__exclude-text {
  font-size: xx-small;
  margin-left: 15px;
}

.cart__separator {
  border-bottom: 1px solid #ddd;
}

.cart__help-block {
  line-height: 18px;
  margin-bottom: 6px;
}

.cart__help-block--italics {
  color: #364956;
  font-style: italic;
}

.cart__subscription-total {
  margin-bottom: 20px;
  margin-top: 12px;
}

.cart__subscription-total--mini {
  margin-top: 5px;
}

.cart--mini {
  font-size: 0.8125rem;
}

#content .cart__submit {
  margin: 40px 0 35px;
}

.billing-options {
  border: 1px solid #ccc;
  margin: 0 0 5px;
}

.billing-options__header {
  border-bottom: 1px solid #ccc;
  font-size: 1rem;
  padding: 10px 15px;
}

.billing-options__body {
  padding: 10px 15px;
}

.billing-options__label {
  display: block;
  font-size: 1rem;
  margin-bottom: 8px;
}

.billing-options__radio {
  margin: 15px 0 18px;
}

.cart__order-process {
  max-width: 1000px;
}

.cart__refund-policy {
  margin-bottom: 20px;
  max-width: 1000px;
}

.cart__permission-text--mini {
  margin-top: 15px;
}

.cart__remove-button {
  background-color: transparent;
  border: none;
}
