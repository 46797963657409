.icon-full-screen {
  color: #364956;
  margin-left: 140px;
}

.icon-full-screen:hover {
  color: #000;
}

.modal-content {
  box-shadow: 0 3px 7px #0000004d;
  height: auto;
}

.modal-header .btn-close {
  margin-left: 0;
}
