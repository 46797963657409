.recovery-options {
  margin-bottom: 30px;
}

.recovery-methods--multiple {
  padding-left: 20px;
}

.recovery-methods__method,
.recovery-methods__method-step {
  height: 0;
  opacity: 0;
  position: relative;
  transition: opacity 0.35s;
  visibility: hidden;
  z-index: 10;

  &.is-active {
    height: auto;
    opacity: 1;
    visibility: visible;
    z-index: 100;
  }
}

.recovery-methods__form-ota-code {
  margin-bottom: 15px;
}

.webauthn--error,
.webauthn--supported,
.webauthn--unsupported {
  display: none;
}

.webauthn__or {
  background: white;
  display: block;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 2;
}

.webauthn__or:after,
.webauthn__or:before {
  border-top: 1px solid #ccc;
  content: ' ';
  display: block;
  height: 0;
  left: 0;
  position: absolute;
  top: 50%;
  width: 45%;
  z-index: -1;
}

.webauthn__or:after {
  left: initial;
  right: 0;
}

.sign-in {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 350px;
  width: 100%;
}
