$close-btn-width: 11px;

@mixin padding-with-close($padding) {
  padding: $padding ($padding * 2 + $close-btn-width) $padding $padding;
}

@mixin close-btn-margin($padding) {
  margin-right: ($padding + $close-btn-width) * -1;
}

@mixin close-well($class, $padding) {
  .well--close#{$class} {
    @include padding-with-close($padding);
  }

  .well--close#{$class} button.close {
    @include close-btn-margin($padding);
  }
}

// Wells with close buttons
@include close-well('', 19px);
@include close-well('.well-large', 24px);
@include close-well('.well-small', 9px);

.well--blue {
  border: 1px solid #567da7;
  background-color: #e6f7ff;
}
