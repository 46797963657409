// redesign variables
@import 'redesign/variables.scss';

@import 'maxmind/montserrat.scss';
@import 'maxmind/roboto.scss';

@import 'vendor/strengthify.css';
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons.scss';
@import 'typeahead.scss';
@import 'number-polyfill.scss';
@import 'maxmind/variables.scss';
@import 'maxmind/mixins.scss';
@import 'maxmind/icons.scss';
@import 'maxmind/buttons.scss';
@import 'maxmind/mm-banners.scss';
@import 'maxmind/forms.scss';
@import 'maxmind/wrappers.scss';
@import 'maxmind/styles.scss';
@import 'maxmind/left-nav.scss';
@import 'maxmind/override-bootstrap.scss';
@import 'maxmind/override-bootstrap-5.scss';
@import 'maxmind/text.scss';
@import 'maxmind/header.scss';
@import 'maxmind/footer.scss';
@import 'maxmind/content.scss';
@import 'maxmind/mm-table.scss';
@import 'maxmind/well-panel.scss';
@import 'maxmind/wells.scss';
@import 'maxmind/modal.scss';
@import 'maxmind/geoip2-upgrade-modal.scss';

// re-usable modules
@import 'maxmind/modules/geoip-demo.scss';
@import 'maxmind/modules/maxmind-solutions.scss';

// page specific styles
@import 'maxmind/account.scss';
@import 'maxmind/login.scss';
@import 'maxmind/cart.scss';
@import 'maxmind/people.scss';
@import 'maxmind/person.scss';
@import 'maxmind/receipt.scss';

@import 'maxmind/privacy.scss';

// admin
@import 'maxmind/admin.scss';
@import 'maxmind/webservice-logs.scss';

// redesign
@import 'redesign/colors.scss';

// HubSpot
@import 'hubspot.scss';

// Recaptcha
@import 'recaptcha.scss';
