.btn [class^='icon-'],
.btn [class*=' icon-'] {
  margin-top: 3px;
}

.btn + .btn,
.btn-container + .btn-container {
  margin-left: 15px;
}

.btn-block + .btn-block {
  margin-left: 0;
}

.btn {
  border: 1px solid #a6abae;
  border-radius: 0;
  box-shadow: none;
  color: #7a7d7f;
  font-family: $displayFontFamily;
  font-size: 0.9375rem;
  line-height: 20px;
  padding: 0.375rem 1.125rem;
  text-shadow: none;
}

.btn:hover,
.btn:focus,
.btn.active,
.btn:active {
  background: #e6e6e6;
  border: 1px solid #a6abae;
  box-shadow: none;
  color: #7a7d7f;
}

.btn:hover,
.btn:focus,
.btn:hover:focus {
  text-decoration: none;
}

.btn--fixed-wide {
  padding: 0.375rem 0;
  width: 215px;
}

.btn--has-icon {
  padding-right: 50px;
  position: relative;
}

.btn__icon {
  margin-top: -0.5em;
  position: absolute;
  right: 18px;
  top: 50%;
}

.btn-primary {
  background: #008fc6;
  border: 1px solid #008fc6;
  border-radius: 0;
  color: #fdfbdc;
  font-weight: 500;

  &:focus,
  &:hover,
  &:active,
  &.active,
  &.disabled,
  &[disabled] {
    background: #006a93;
    border: 1px solid #006a93;
    color: #fdfbdc;
    outline: 0;
  }

  &:active {
    box-shadow: 0 0 0 3px #78cef9;
  }
}

.btn-primary--inverse {
  background: none;
  border: 2px solid #008fc6;
  color: #008fc6;

  &:focus,
  &:hover,
  &:active,
  &.active {
    background: none;
    border: 2px solid #006a93;
    color: #006a93;
    outline: 0;
  }

  &:active {
    box-shadow: inset 0 0 8px 0 #78cef9;
  }
}

.btn-secondary {
  background: $secondaryLinkColor;
  border: none;
  border-radius: 0;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.875rem 3rem;
  text-transform: uppercase;

  &:focus,
  &:hover,
  &:active,
  &.active {
    background: $secondaryLinkHoverColor;
    border: none;
    color: #fff;
    outline: 0;
  }

  &:active {
    box-shadow: inset 0 0 8px 4px rgba(0, 0, 0, 0.3);
  }
}

.btn-secondary--inverse {
  background: #fff;
  border: 2px solid $secondaryLinkColor;
  color: $secondaryLinkColor;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;

  &:focus,
  &:hover,
  &:active,
  &.active {
    background: #fff;
    border: 2px solid $secondaryLinkHoverColor;
    color: $secondaryLinkHoverColor;
    outline: 0;
  }

  &:active {
    box-shadow: inset 0 0 8px 0 $secondaryLinkHoverColor;
  }
}

.btn-danger {
  background: #fff;
  border: 1px solid #d0021b;
  color: #d0021b;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.disabled,
.btn-danger[disabled] {
  background: #feeaed;
  border: 1px solid #d0021b;
  color: #d0021b;
}

.btn-danger-fill {
  background: #f94d4d;
  border: 1px solid #f94d4d;
  color: #fdfbdc;
}

.btn-danger-fill:hover,
.btn-danger-fill:focus,
.btn-danger-fill:active,
.btn-danger-fill.active,
.btn-danger-fill.disabled,
.btn-danger-fill[disabled] {
  background: #d0021b;
  border: 1px solid #d0021b;
  color: #fdfbdc;
}

.btn-purchase {
  background: #7ed321;
  border: 1px solid #7ed321;
  border-radius: 0;
  color: #fdfbdc;

  &:focus,
  &:hover,
  &:active,
  &.active {
    background: #5c9b17;
    border: 1px solid #5c9b17;
    color: #fdfbdc;
  }

  &:focus,
  &:active {
    box-shadow: 0 0 2px 2px #7ed321;
    outline: none;
  }
}

.btn-demo {
  background-color: #26343d;
  color: #fff;
  font-size: 1.125rem;
  margin-left: 8px;
  text-decoration: none;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.disabled,
  &[disabled] {
    color: #fff;
    background-color: #12191e;
  }
}

.btn-info {
  border-radius: 0;
  font-size: 0.9375rem;
  font-weight: $weightMedium;
  padding: 6px 18px;
}

.btn-info--primary {
  background: #fda527;
  border: 2px solid #fda527;
  color: #fdfbdc;

  &:focus,
  &:hover,
  &:active {
    background: #f48217;
    color: #fdfbdc;
    border: 2px solid #f48217;
  }

  &:focus,
  &:active {
    box-shadow: 0 0 2px 2px fade(#fda527, 50%);
    outline: none;
  }
}

.btn-info--secondary {
  background: #fff;
  border: 2px solid #fda527;
  color: #f59223;

  &,
  &:focus,
  &:hover,
  &:active {
    color: #f59223;
  }

  &:focus,
  &:hover,
  &:active {
    background: #fcf6ec;
    border: 2px solid #f48217;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 0 2px 2px fade(#fda527, 50%);
  }
}

.btn-ghost {
  background: transparent;
  border: 1px solid #a6abae;
  color: #7a7d7f;
}

// This is to fix up some styles we set that interfere
// with the Bootstrap styling.
.btn-link {
  border: 0;
  font-family: inherit;
}

.btn-link:hover,
.btn-link:focus,
.btn-link.active,
.btn-link:active {
  background: transparent;
  border: 0;
  box-shadow: none;
}

.form-link .btn-link {
  font-size: 16px;
  padding: 0;
  vertical-align: top;
}
