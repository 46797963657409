// http://stackoverflow.com/a/16916490/406224
.table .text-right {
  text-align: right;
}
.table .text-left {
  text-align: left;
}
.table .text-center {
  text-align: center;
}

.fixed-table-body::-webkit-scrollbar {
  -webkit-appearance: none;
}

.fixed-table-body::-webkit-scrollbar:horizontal {
  height: 11px;
}

.fixed-table-body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid #fff;
  background-color: rgba(0, 0, 0, 0.24);
}

.fixed-table-body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.fixed-table-body::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

#grid {
  .core {
    @mixin span($gridColumns) {
      width: ($gridColumnWidth * $gridColumns) +
        ($gridGutterWidth * ($gridColumns - 1));
    }
  }
}

.control-label {
  line-height: $contentLineHeight;
}

#content .media-list {
  margin-left: 0;
}

.media:first-child,
#content .media:first-child {
  margin-top: 0;
}

.media,
#content .media {
  background: none;
  margin-top: 35px;
  padding: 0;
}

.media-heading,
#content .media-heading {
  line-height: 1.33333333em;
  margin: 0 0 10px;
}

.media > .pull-left {
  margin-right: 25px;
}

.tooltip {
  font-size: 0.875rem;
}

.tooltip.in {
  opacity: 1;
  filter: alpha(opacity=100);
}

.tooltip.right .tooltip-arrow {
  border-right-color: #ccc !important;
}

.tooltip > .tooltip-inner {
  background: #fff;
  border: 1px solid #ccc;
  color: $textColor;
}

.modal--small {
  width: 400px;
  margin-left: -200px;
}

.modal-footer--center {
  text-align: center;
}

.input-prepend--no-bg .add-on {
  background: none;
  border: none;
  padding-left: 0;
}

.input-prepend--no-bg input {
  border-radius: 0px;
}

.form-actions {
  border-top: none;
  background: none;
}

.alert {
  border-radius: 0;
}

.alert .close {
  top: 0;
}

.well {
  border-radius: 0;

  .close {
    line-height: 20px;
    position: relative;
    right: -21px;
    top: 0;
  }
}

.well--dismissable {
  padding-right: 35px;
}

#content {
  .pagination ul {
    margin: 0;
  }

  .pagination ul li:before {
    content: '';
  }
}

.modal-backdrop {
  background: white;
}
