#pipl-form .form-group {
  margin-bottom: 20px;
}

#deletion-follow-up.form-group,
#revocation-follow-up.form-group {
  margin-top: 10px;
  margin-bottom: 10px;
}

#pipl-form input[type='text'] {
  margin: 0px;
}

#pipl-form textarea {
  float: none;
  background-color: #fff;
  border-color: #ccc;
  color: #555;
  font-size: 15px;
}

#pipl-form .help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
  font-size: 14px;
}
