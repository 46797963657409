body,
html {
  height: 100%;
}

body {
  -webkit-font-smoothing: subpixel-antialiased;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: 100%;
  font-size: 100%;
  position: relative;

  @media (max-width: $bp-xs-max) {
    padding-left: 0;
    padding-right: 0;
  }
}

a:hover:focus {
  text-decoration: underline;
}

a:focus {
  text-decoration: none;
}

dl {
  margin-top: 0;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

hr {
  border: 0;
  border-top: 1px solid #576672;
  height: 0;
  margin: 1.25rem 0;
}

img {
  border-style: none;
}

figure {
  margin: 0;
  padding: 0;
}

input,
textarea,
select {
  vertical-align: middle;
}

form,
fieldset {
  border-style: none;
  margin: 0;
  padding: 0;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

select:invalid {
  border-color: rgb(222, 226, 230);
  color: rgb(33, 37, 41);
  box-shadow: none;
}

input.is-empty:focus:invalid,
textarea.is-empty:focus:invalid,
select:focus:invalid:focus {
  border-color: rgba(82, 168, 236, 0.8);
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(82, 168, 236, 0.6);
  color: rgb(85, 85, 85);
}

section {
  &:nth-of-type(2n) {
    background: #f8f8f8;
  }
}

.section {
  outline: 0;
  padding-bottom: 40px;
  padding-top: 40px;
  position: relative;
}

.section__heading {
  margin-bottom: 6.25rem;
  position: relative;
}

.heading-primary {
  color: $mmGray;
  font-size: 2.25rem;
  font-weight: $weightLight;
  line-height: normal;

  @media (min-width: $bp-md-min) {
    font-size: 2.875rem;
  }
}

.heading-secondary {
  color: $mmBlue;
  font-size: 1.75rem;
  font-weight: $weightMedium;
  line-height: 2.25rem;

  @media (min-width: $bp-md-min) {
    font-size: 2.25rem;
    line-height: 3rem;
  }
}

.main-holder {
  margin: 0 auto;
  padding: 25px 0 0;
}

.twocolumns {
  overflow: hidden;
}

/* Note that the following styles are used in multiple places: geoip demo form, account geoip lookup, etc */
#my-ip-demo table,
#geoip-demo {
  margin-top: 0;

  .mark {
    font-size: 0.8125rem;
  }
}

#geoip-demo:before,
#geoip-demo-form:before,
#geoip-demo #result-table:before {
  display: none !important;
}

.geoip-demo__addresses {
  height: 200px;
  max-width: 500px;
}
/* Note that the above styles are used in multiple places: geoip demo form, account geoip lookup, etc */

.form {
  margin: 0 0 5px;
  padding: 0;

  fieldset {
    overflow: hidden;
  }

  textarea {
    background: #e0ecf3;
    border: 1px solid #5a7483;
    color: #5e6d78;
    float: left;
    font-size: 0.6875rem;
    height: 38px;
    line-height: 14px;
    margin: 0;
    overflow: auto;
    padding: 5px;
    resize: none;
    width: 422px;
  }
}

.top-heading {
  border-bottom: 1px solid $maxmindDarkBlueGray;
  margin: 0 0 26px 0;
}

.breadcrumbs {
  color: #5a6a75;
  font-weight: 600;
  font-size: 0.5625rem;
  line-height: 12px;
  list-style: none;
  margin: 0 0 -2px;
  overflow: hidden;
  padding: 0;
  text-transform: uppercase;

  li {
    background: url('../images/bullet.png') no-repeat 100% 3px;
    float: left;
    margin: 0 6px 0 0;
    padding: 0 7px 0 0;
  }

  a {
    color: #5a6a75;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

#content {
  pre {
    margin: 0 0 22px;
  }

  blockquote {
    background: url('../images/quote.png') no-repeat 0 30px;
    padding: 23px 0 0 22px;
    width: 200px;

    q {
      color: $maxmindBrightBlue;
      display: block;
      font:
        italic 20px/28px Georgia,
        Georgia,
        serif;
      margin: 0 0 11px;
    }

    cite {
      color: $maxmindDarkBlueGray;
      display: block;
      font-size: 0.6875rem;
      font-style: normal;
      line-height: 14px;
      padding: 0 9px 0 0;
      text-align: right;

      span {
        display: block;
      }
    }

    &.alignright {
      float: right;
      margin: 0 14px 0 10px;
    }

    &.alignleft {
      float: left;
      margin: 0 10px 0 14px;
    }
  }

  p.list-header {
    margin: 0 0 10px;
  }

  ul,
  ol {
    margin: 0 0 20px 19px;
    padding: 0;

    li {
      line-height: $contentLineHeight;
      margin-bottom: 10px;
      padding: 0;

      ul,
      ol {
        margin-bottom: 15px;
        margin-top: 15px;
      }
    }
  }

  ul.inline {
    li:first-child {
      background: none;
    }
  }

  .pagination li {
    background: none;
    padding: 0;
  }

  .pagination .active {
    background-color: rgb(245, 245, 245);
  }

  .block p {
    font-weight: 600;
    margin: 0 0 7px;
    text-transform: uppercase;
  }

  form {
    margin-bottom: 24px;
  }

  .control-group p.help-block {
    margin: 0;
  }

  .control-group span.help-block {
    font-size: 0.6875rem;
  }
}

.cta-widgets {
  display: flex;
}

.cta-widget {
  background: $ctaBackground;
  border: 1px solid $ctaBorderColor;
  padding: 10px 12px 15px;
}

.cta-widget__heading,
.cta-widget__content {
  color: $ctaHeadingColor;
  font-size: 0.875rem;
  line-height: $contentLineHeight;
}

.cta-widget__heading {
  display: block;
  font-weight: $weightBold;
  margin: 0 0 10px;
}

.cta-widget__content {
  display: block;
}

.cta-widget__button {
  display: block;
  font-size: 0.875rem;
  margin-top: 20px;
}

.cta-widget--inline {
  box-sizing: border-box;
  width: 260px;

  .cta-widget__heading,
  .cta-widget__content {
    font-size: 0.9375rem;
  }

  .cta-widget__button {
    display: inline-block;
    font-size: 0.9375rem;
  }

  & + & {
    margin-left: 20px;
  }
}

#sidebar {
  margin-bottom: 125px;
  margin-right: 25px;
  margin-top: 0;
}

#ccfd_formula tr {
  td:nth-child(5) {
    padding-right: 15px;
    padding-left: 15px;
  }
  td:nth-child(3) {
    padding-right: 15px;
    padding-left: 15px;
    text-align: right;
  }
}

abbr[title] {
  border-bottom: 0;
  cursor: help;
}

table td.subheading {
  background-color: darken($tableBackgroundAccent, 10%) !important;
}

.receipt td,
.receipt th {
  padding: 0.25em;
}

.table-small {
  font-size: 0.8125rem;
}

.table-overflow-container {
  margin-bottom: 20px;
  overflow-x: auto;

  .table {
    margin-bottom: 0;
  }
}

.order-dropdown {
  padding-top: 15px;
  padding-bottom: 25px;
  text-align: center;

  .form-inline {
    display: inline-block;
    margin-bottom: 0px !important;
    padding-bottom: 14px;
    text-align: center;
  }

  .demo-link {
    margin-top: 8px;
  }
}

#sidebar-toggle-switch-container {
  padding-bottom: 15px;
}

#sidebar-toggle-switch {
  width: 162px;
}

.transparent {
  background-color: transparent;
  border: 0;
}

.auto-width {
  width: auto;
}

@media print {
  a[href]:after {
    content: none;
  }
}

.table-centered-content {
  td,
  th {
    text-align: left;
  }

  td + td,
  th + th {
    text-align: center;
  }
}

.table-nowrap-left-col {
  tr td:first-child {
    white-space: nowrap;
  }
}

.table-nonfluid {
  width: auto;
}

.row-left-content {
  td,
  th {
    text-align: left;
  }

  td + td,
  th + th {
    text-align: left;
  }
}

.dl-left-compact {
  dt {
    width: 80px;
    text-align: left;
    font-weight: normal;
  }

  dd {
    margin-left: 100px;
  }
}

.blog-content {
  height: 40px;
}

.introducing-content {
  height: 135px;
}

div.sendgrid-subscription-widget {
  .success {
    @extend .alert;
    @extend .alert-success;
  }

  .error {
    @extend .alert;

    @extend .alert-danger !optional;
  }

  form {
    @extend .form-inline;

    span {
      font-weight: bold;
    }

    input,
    span,
    label {
      padding-right: 10px;
    }

    label {
      display: inline;
    }

    input[type='submit'] {
      @extend .btn;
      @extend .btn-primary;
    }
  }
}

.contact-cta {
  position: relative;
}

.contact-cta__button {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  word-wrap: break-word;
  width: 115px;
}

.contact-cta__text {
  padding-top: 10px;
  width: 470px;
}

div.cta {
  background-color: rgb(170, 170, 170);
  border: 0px;
}

div.error-links li {
  background-image: none !important;
  padding-left: 0px !important;
}

textarea.description {
  width: 340px;
}

.checkout .control-label {
  margin-right: 10px;
  width: 250px;
}

.cc-images {
  padding-left: 20px;
}

.reset-left-margin {
  margin-left: 0px;
}

.cta-spaced {
  display: inline-block;
  margin: 5px 0 25px;
}

form .align-with-controls {
  margin-left: 80px;
}

form .expiration {
  width: 104px;
}

.info-widget {
  min-height: 230px;
}

.purchase-widget {
  position: relative;
}

#content .purchase-widget__form {
  margin-bottom: 0;
}

.purchase-widget__dropdown {
  margin: 0;
}

.purchase-widget__btn {
  margin-top: 20px;
}

#content .purchase-widget__other-input-wrapper {
  margin: 5px 0 0;
}

#content .purchase-widget__other-input-wrapper--horizontal {
  margin: 0 5px;
}

#content .purchase-widget__other-input {
  margin: 0;
  width: 178px;
}

#content .purchase-widget__other-input--horizontal {
  margin: 0;
  width: 90px;
}

.query-usage-summary {
  font-weight: bold;
}

.usage-report-table {
  width: auto;
}

.usage-report-table-date-cell {
  width: 125px;
}

.usage-report-table-cell {
  text-align: right !important;
  width: 90px;
}

.usage-report-table-summary-cell {
  font-weight: bold;
}

.usage-report-table-summary-total-cell {
  text-align: right !important;
}

.usage-report-date-picker-input {
  cursor: pointer !important;
  width: 100px;
}

.usage-report-date-error {
  border: 1px solid $errorText !important;
}

.usage-report-selector {
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
}

#content .usage-report-selector__heading {
  font-weight: 600;
  margin-bottom: 10px;
}

#content .usage-report-selector__help-text {
  display: block;
  margin-bottom: 10px;
}

#content .usage-report-selector__picker {
  margin-bottom: 15px;
}

#usage_report_default_window_selector {
  margin-bottom: 20px;
  width: 100%;
}

.usage-report-submit-btn {
  @extend .roundCorners;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}

.usage-report-service-heading {
  color: black !important;
  font-size: 1.0625rem !important;
  font-weight: bold !important;
}

.credit-card {
  background: url('../images/cc-group.png') no-repeat;
  display: inline-block;
  vertical-align: middle;
}

.credit-card[data-card-type='MasterCard'] {
  background-position: 0 0;
  height: 43px;
  width: 61px;
}

.credit-card[data-card-type='VISA'] {
  background-position: -64px 0;
  height: 44px;
  width: 64px;
}

.credit-card[data-card-type='American Express'] {
  background-position: -127px 0;
  height: 44px;
  width: 45px;
}

.credit-card[data-card-type='Discover'] {
  background-position: -3px -47px;
  height: 37px;
  width: 58px;
}

.user-permission-request-row-pending {
  background: $warningBackground;
}
.user-permission-request-row-approved {
  background: $successBackground;
}
.user-permission-request-row-rejected {
  background: $errorBackground;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.select--small {
  font-size: 0.875rem;
}

.text-small {
  font-size: 0.625rem;
}

.list-lower-alpha {
  list-style: lower-alpha;
}

.list-lower-roman {
  list-style: lower-roman;
}

// Used by Mojo form validation
label.field-with-error,
small.field-with-error {
  color: #b94a48;
}

input.field-with-error,
select.field-with-error,
textarea.field-with-error {
  border: 1px solid #b94a48;
}

// These are intended to match their BS3/BS4 equivalents
@mixin bg-variant($color) {
  background-color: $color;
  a#{&}:hover,
  a#{&}:focus {
    background-color: darken($color, 10%);
  }
}

.bg-primary {
  color: #fff;
  @include bg-variant(#337ab7);
}

.bg-success {
  @include bg-variant(#dff0d8);
}

.bg-info {
  @include bg-variant(#d9edf7);
}

.bg-warning {
  @include bg-variant(#fcf8e3);
}

.bg-danger {
  @include bg-variant(#f2dede);
}

.strengthify-wrapper {
  font-size: 80%;
}

.unclickable {
  pointer-events: none;
}

.table-striped--blue {
  > tbody > tr:nth-of-type(odd) > td {
    background-color: #def2fc;
  }

  > tbody > tr:nth-of-type(even) > td {
    background-color: #c8eafb;
  }
}

.table-striped {
  > tbody > tr.row--blue-odd > td {
    background-color: #def2fc;
  }

  > tbody > tr.row--blue-even > td {
    background-color: #c8eafb;
  }
}

.result-table__table {
  max-height: 80vh;
}

.sticky-table {
  border-top: 0;
}

.sticky-table__wrapper {
  position: relative;
  margin-bottom: 15px;
}

.sticky-table th,
.sticky-table td {
  background: white;
  border-bottom: 1px solid #ddd !important;
  border-top: 1px solid #ddd !important;
  position: sticky;
  top: 0;
  white-space: nowrap;
}

.sticky-table tr:nth-child(even) {
  background: white;
}

.sticky-table th:first-child,
.sticky-table td:first-child {
  border-right: 2px solid #bbb;
  left: 0;
  position: sticky;
  width: 120px;
}

.sticky-table th:first-child,
.sticky-table td:first-child {
  z-index: 100;
}

.sticky-table.table-striped tbody > tr:nth-child(even) > td {
  background: white;
}

.sticky-table__scroller {
  overflow-x: scroll;
  padding-bottom: 5px;
  width: 100%;
}

.result-table__error {
  border: 1px solid #ddd;
  margin: 15px 0;
  max-width: 450px;
  padding: 5px 10px;
  width: 100%;
}

.account-logged-in-svg {
  enable-background: new 0 0 50 50;
}

.account-logged-out-svg {
  enable-background: new 0 0 35 42;
}

.cart-svg {
  enable-background: new 0 0 50 50;
}

.cart-empty-svg {
  enable-background: new 0 0 38 32;
}

.search-svg {
  enable-background: new 0 0 41 41;
}

.pointer {
  cursor: pointer;
}

.fit-content {
  width: fit-content;
}

.receipt-foreign-currency {
  font-size: 0.875rem;
  font-weight: normal;
  color: grey;
}
