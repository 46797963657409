h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h5,
.h5 {
  font-family: $displayFontFamily;
}

.h1 {
  color: $maxmindBrightBlue;
  font-size: 1.5rem;
  font-weight: $weightMedium;
  line-height: $contentLineHeight;
  margin: 10px 0 5px;
}

#content {
  h1,
  .h1 {
    @extend .h1;
    margin-bottom: 15px;
  }

  h2,
  .h2 {
    color: $maxmindDarkBlueGray;
    font-size: 1.25rem;
    font-weight: $weightBold;
    line-height: $contentLineHeight;
    margin: 0 0 13px;
  }

  h3,
  .h3 {
    color: $maxmindDarkBlueGray;
    font-size: 1.125rem;
    font-weight: $weightBold;
    line-height: $contentLineHeight;
    margin: 0 0 8px;
  }

  h4,
  .h4 {
    color: $maxmindDarkBlueGray;
    font-size: 1rem;
    font-weight: $weightBold;
    line-height: $contentLineHeight;
    margin-bottom: 5px;
  }

  h5,
  .h5 {
    color: $maxmindDarkBlueGray;
    font-size: 0.9375rem;
    font-weight: $weightBold;
    line-height: $contentLineHeight;
    padding: 3px 0 0;
  }

  h6,
  .h6 {
    color: $maxmindDarkBlueGray;
    font-size: 0.9375rem;
    font-weight: $weightBold;
    line-height: $contentLineHeight;
    margin: 0 0 2px;
  }

  .heading-important {
    color: #d0021b;
    font-weight: $weightNormal;
  }

  p {
    color: $maxmindDarkBlueGray;
    font-size: 0.9375rem;
    font-weight: $weightNormal;
    line-height: $contentLineHeight;
  }

  .alert p {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    font-size: 0.9375rem;
  }

  .text-error {
    color: #b94a48;
    margin-bottom: 5px;
  }
}
