$solutionHorizontalPadding: 20px;

.mm-solutions__section {
  @extend .pattern-default;
}

.mm-solutions__container {
  position: relative;
  z-index: 2;
}

.mm-solutions__heading {
  color: #fff;
  font-weight: 700;
  margin: 0 0 40px;
  padding: 0 40px;
  text-shadow: 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.mm-solutions {
  @media (min-width: $bp-sm-min) {
    display: flex;
    justify-content: center;
  }
}

.mm-solution {
  align-items: center;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 420px;
  position: relative;
  width: 100%;

  & + & {
    margin-top: 30px;
  }

  @media (min-width: $bp-sm-min) {
    margin: 0 20px;
    width: 420px;

    & + & {
      margin-top: 0;
    }
  }
}

.mm-solution__content {
  @extend .hyphenated-word-wrap;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  text-align: center;
  width: 100%;
}

.mm-solution__heading {
  margin: 0 0 20px;
}

.mm-solution__heading--minfraud {
  color: $mmMinfraud;
}

.mm-solution__heading--geoip {
  color: $mmGeoipDB;
}

.mm-solution__heading-sup {
  font-size: 90%;
  top: -0.1em;
}

.mm-solution__heading-small {
  display: block;
  font-size: 1.375rem;
  font-weight: $weightNormal;
  line-height: 1.875rem;
}

.mm-solution__subheading {
  background: #f5f6f7;
  border-top: 1px solid #ddd;
  font-size: 1rem;
  font-weight: $weightMedium;
  margin: 0;
  padding: 20px 0 10px;

  @media (min-width: $bp-md-min) {
    font-size: 1.125rem;
  }
}

.mm-solution__image {
  margin: 40px auto 20px;
  max-width: 100px;
  width: 100%;
}

.mm-solution__description {
  align-content: top;
  background: #f5f6f7;
  color: $mmGray;
  flex: 1 0 auto;
  font-size: 0.875rem;
  hyphens: none;
  line-height: 1.3125rem;
  margin: 0;
  overflow-wrap: normal;
  padding: 0 40px 20px;
  word-wrap: normal;

  @media (min-width: $bp-md-min) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@mixin mm-solution__btn--fullwidth {
  left: $solutionHorizontalPadding;
  padding-left: 10px;
  padding-right: 10px;
  right: $solutionHorizontalPadding;
}

.mm-solution__btn--fullwidth {
  @include mm-solution__btn--fullwidth;
}

.mm-solution__footer {
  align-content: center;
  background: #eee;
  border-radius: 0 0 6px 6px;
  border-top: 1px solid #ddd;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 100%;
}

.mm-solution__btn {
  @media (max-width: $bp-xxs-max) {
    @include mm-solution__btn--fullwidth;
    transform: none;
  }

  @media (min-width: $bp-sm-min) and (max-width: $bp-sm-max) {
    @include mm-solution__btn--fullwidth;
    transform: none;
  }
}
