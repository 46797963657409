.well-panel {
  position: relative;
  margin-bottom: 20px;
}

.well-panel__top {
  margin-bottom: 0;
  position: relative;
}

.well-panel__bottom {
  background: #f4fafa;
  position: absolute;
}

.well-panel__bottom-content {
  border: 1px solid #567da7;
  display: block;
  margin-top: -4px;
  padding: 19px;
}

.well-panel__cta {
  margin-top: 15px;
  text-align: center;
}
